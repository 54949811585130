import React from "react";
import { Link } from "react-scroll";
import { AppBar, Toolbar } from "@mui/material";
import "./topbar.css";
import { useEffect } from "react";
import { useState } from "react";

const navigations = [
  {
    name: "For Drivers",
    link: "drivers",
  },
  {
    name: "For Vendors",
    link: "vendors",
  },
  {
    name: "Contact Us",
    link: "contact",
  },
];

const Topbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const NavButton = ({ text, link }) => {
    return (
      <Link
        activeClass="active"
        smooth
        spy
        to={link}
        className="link2"
        style={{ marginLeft: "10%" }}
      >
        {text}
      </Link>
    );
  };

  return (
    <div>
      <AppBar
        color="primary"
        sx={{
          borderRadius: scrolled ? 0 : "35px", // Remove border radius on scroll
          backgroundColor: "#5A5A5A",
          position: "fixed",
          marginTop: scrolled ? 0 : 4, // Remove marginTop on scroll
          marginRight: scrolled ? 0 : 10, // Remove marginRight on scroll
          zIndex: 1000,
          width: scrolled ? "100%" : "90%", // Change width on scroll
          transition: "0.3s ease-in-out", // Add smooth transition
        }}
      >
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Link activeClass="active2" smooth spy to="#" className="link">
            KIRFU
          </Link>

          {navigations.map((nav, index) => (
            <NavButton key={index} text={nav.name} link={nav.link} />
          ))}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Topbar;
