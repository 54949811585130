import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import backgroundImage from "../../assets/bgImgtree.png";
import "./about.css";

const About = () => {
  const AboutData = [
    {
      name: "Efficient Routes, Real-Time Tracking",
      content:
        "KIRFU ensures efficient deliveries with smart route planning, cutting down time and fuel costs. Our real-time tracking keeps you and your customers informed throughout the process. Efficient deliveries, peace of mind – that's KIRFU.",
      bgColor: "#D9D9D9",
      color: "#000000",
    },
    {
      name: "Join Our Thriving Community of Drivers",
      content:
        "Become a part of KIRFU's thriving community of drivers and unlock a world of opportunities. Enjoy the freedom of flexible working hours that fit your schedule. With KIRFU, you're not just a driver – you're part of a supportive community that nurtures growth and success. Embrace the driver lifestyle with KIRFU today!",
      bgColor: "#DBDBDB52",
      color: "#F2F2F2",
    },
    {
      name: "Empowering Vendors, Driving Success!",
      content:
        "Partner with KIRFU for seamless integration, faster deliveries, and a collaborative environment. Expand your customer base and optimize logistics with our advanced technology. Benefit from real-time insights, transparent communication, and dedicated support. Join our thriving community of vendors and drive towards success together.",
      bgColor: "#D9D9D9",
      color: "#000000",
    },
  ];

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "auto",
        backgroundPosition: "center",
        width: "100%",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography color="#fff" fontWeight={500} fontSize="22px">
          How Does Kirfu Work?
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly", // Evenly space cards horizontally
          flexWrap: "wrap", // Wrap cards to next line if needed
          gap: "20px", // Add some spacing between cards
          marginTop: "8%",
        }}
      >
        {AboutData.map((contents, index) => (
          <Card
            key={index}
            sx={{
              width: "22%",
              height: "372px",
              borderRadius: "22px",
              backgroundColor: contents.bgColor,
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                fontWeight={500}
                fontSize="18px"
                align="center"
                color={contents.color}
              >
                {contents.name}
              </Typography>
              <Typography
                color={contents.color}
                fontSize="15px"
                fontWeight={400}
                marginTop="18%"
                align="center"
              >
                {contents.content}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default About;
