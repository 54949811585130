import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const NoteBox = styled('div')({
  backgroundColor: '#9376E0',
  border: '1px solid #ccc',
  padding: '20px',
  marginBottom: '20px',
  textAlign: 'center',
});

const BrandName = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '28px',
  marginBottom: '0px',
  color:"#F2F2F2"
});

const Slogan = styled(Typography)({
  fontSize: '12px',
  color: '#777',
  color:'#BCB4B4',
});

const ExploreLink = styled(Link)({
  color: '#007bff',
  textDecoration: 'underline',
});

function UnderConstructionNote() {
  return (
    <Container maxWidth="sm">
      <NoteBox>
        <BrandName variant="h5" gutterBottom>
          KIRFU
        </BrandName>
        <Slogan variant="body2" gutterBottom>
          Leveraging AI
        </Slogan>
        <Typography variant="body1">
          Our mobile view is currently under construction to enhance your experience. Explore our{' '}
          <ExploreLink href="#">
            desktop version
          </ExploreLink>{' '}
          in the meantime!
        </Typography>
      </NoteBox>
    </Container>
  );
}

export default UnderConstructionNote;
