import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "./drivers.css";
import carouselImg1 from "../../assets/carouselImg.png";
import Carousel from "react-material-ui-carousel";

function Drivers() {
  const carouselData = [
    {
      imageName: carouselImg1,
      title: "Effortless Onboarding, Seamless Freedom: Drive and Lead!",
      content:
        "Be Your Own Boss. Experience the true freedom and flexibility of being a KIRFU driver. Our simple and efficient onboarding process ensures that you can start driving in no time. With an intuitive and user-friendly app design, managing deliveries and tracking your earnings has never been easier. Navigate optimized routes effortlessly, stay connected with customers, and enjoy the seamless experience of delivering with KIRFU. Join our driver community today and unlock a world of convenience, growth, and success. Drive with KIRFU and experience the future of effortless deliveries!",
    },
    {
      imageName: carouselImg1,
      title: "Stay Ahead with Real-Time Tracking and Optimal Routes",
      content:
        "We understand the importance of efficiency in your delivery journeys. That's why we've incorporated real-time tracking and optimal route planning into our advanced technology. With our user-friendly app, you and your customers stay updated throughout the delivery process, providing real-time location information for parcels in transit. Bid farewell to unnecessary detours and delays, as KIRFU's optimal route planning feature calculates the most efficient paths for timely deliveries. Navigate with ease, maximize productivity, and reduce fuel consumption with KIRFU. Embrace a seamless and satisfying delivery experience, where every journey is optimized for success.",
    },
    {
      imageName: carouselImg1,
      title: "Driver-Centric Services: Transparent Payments & Support",
      content:
        "We believe in transparency and empowering our drivers with the best support and tools for success. Our transparent payment system ensures that you receive accurate and timely earnings for every completed delivery, with detailed breakdowns for each transaction. We value your feedback and provide a dedicated support line, available 24/7, to address any questions or concerns promptly. To help you thrive, our performance analysis feature offers valuable insights into your delivery performance, enabling you to make data-driven decisions and enhance your efficiency. Additionally, our customer ratings system ensures that your hard work and excellent service are recognized, creating opportunities for growth within the KIRFU community. Join us today and experience the benefits of transparent payments, dedicated support, performance analysis, and well-deserved recognition as a valued KIRFU driver.",
    }
  ];

  return (
    <section id="drivers">
      <div className="driver-container">
        <div style={{ textAlign: "center" }}>
          <Typography className="textStyle" fontSize={"32px"}>
            For Drivers
          </Typography>
        </div>
        <Carousel
          indicatorIconButtonProps={{
            style: {
              // padding: "10px", // 1
              marginTop: "20px",
              // color: "blue", // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: '#9376E0' // 2

            }
          }}
          activeIndicatorIconButtonChildren={
            <span style={{ backgroundColor: '#9376E0' }} /> // Set the inner color
          }
          animation="slide"
          sx={{ marginTop: "8%", marginLeft: "4%", marginRight: "4%" }}
        >
          {carouselData.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

function Item(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px", // Adjust the gap between the image and card container
        marginLeft: "5%",
      }}
    >
      <div
        style={{
          width: "100%", // You can adjust this percentage based on your layout needs
          height: "100%", // You can adjust this percentage based on your layout needs
        }}
      >
        <img
          src={carouselImg1}
          alt="carousel"
          style={{ width: "70%", height: "70%", }}
        />
      </div>
      <div style={{
        width: "100%", // You can adjust this percentage based on your layout needs
        height: "100%", // You can adjust this percentage based on your layout needs
      }} >
        <Card
          sx={{
            width: "80%",
            height: "462px",
            borderRadius: "22px",
            // marginLeft: "15%",
            backgroundColor: "#8585854D",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "#fff",
            }}
          >
            <Typography fontWeight={500} fontSize={"18px"} align="center">
              {props.item.title}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"18px"} // Adjust the margin as needed
              align="center"
            >
              {props.item.content}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Drivers;
