import React, { useState } from "react";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Drivers from "./scenes/drivers";
import Vendors from "./scenes/vendors";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Contact from "./scenes/contact/Contact";
import UnderConstructionNote from "./scenes/global/UnderConstructionNote";
import { useEffect } from "react";
import Footer from "./scenes/footer/Footer";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif", // Use the 'Poppins' font family
  },
});

function App() {
  const [isLaptopScreen, setIsLaptopScreen] = useState(false);

  const checkScreenSize = () => {
    const isLaptop = window.innerWidth >= 1330; // Adjust this value as needed
    setIsLaptopScreen(isLaptop);
  };

  useEffect(() => {
    checkScreenSize(); // Initial check
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <main className="content">
          {isLaptopScreen ? (
            <>
              <Topbar />
              <CssBaseline />
              <Dashboard />
              <Drivers />
              <Vendors />
              <Contact />
              <Footer />
            </>
          ) : <UnderConstructionNote />}
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
