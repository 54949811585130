import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./dashboard.css";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import deliveryTruck from "../../assets/deliverytruck.png";
import About from "./About";

function Dashboard() {
  const handleLearnMore = () => { };

  return (
    <section id="#" style={{ color: "white" }}>
      <Box className="container">
        <div style={{ textAlign: "center" }}>
          <Typography className="textStyle" fontSize={"32px"}>
            Welcome to{" "}
            <span style={{ fontWeight: 700 }}>
              KIRFU - Your Delivery Revolution
            </span>
          </Typography>
          <p style={{ fontSize: "18px" }}>
            We connect drivers, vendors, and customers on a seamless platform,
            optimizing routes and tracking parcels in real-time. Join us and
            thrive in the world of efficient deliveries!
          </p>
          <Button
            sx={{
              m: "18px",
              backgroundColor: "#D9D9D9",
              color: "#000",
              padding: "10px",
              '&:hover': {
                backgroundColor: '#9376E0', // Set the hover color
                color: 'white', // Text color on hover
              },
            }}
            variant="contained"
            onClick={() => handleLearnMore()}
            endIcon={<ArrowRightAltOutlined />}
          >
            <Typography
              fontSize="12px"
              textTransform={"capitalize"}
              fontFamily="Poppins, sans-serif"
            >
              <span style={{ fontWeight: "600" }}>Learn more</span>{" "}
            </Typography>
          </Button>
          <Box
            sx={{
              m: "30px",
            }}
          >
            <img src={deliveryTruck} alt="delivery-truck" />
          </Box>
        </div>
      </Box>
      <About />
    </section>
  );
}

export default Dashboard;
