import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "./vendors.css";
import carouselImg1 from "../../assets/carouselImg.png";
import Carousel from "react-material-ui-carousel";

function Vendors() {
  const carouselData = [
    {
      imageName: carouselImg1,
      title: "Optimized Routes for Swift and Accurate Deliveries",
      content:
        "Our advanced technology ensures streamlined processes, enabling you to manage orders seamlessly. With real-time tracking and optimized routes, delivery speed and accuracy are greatly improved. Your customers will receive their parcels promptly, and you can provide them with accurate delivery updates every step of the way. Say goodbye to unnecessary delays and errors, as KIRFU empowers you to deliver with unparalleled speed and precision. ",
    },
    {
      imageName: carouselImg1,
      title: "Unlocking Business Potential: Analysing Metrics",
      content:
        "With our dedicated vendor portal, access valuable analytics and metrics that provide a comprehensive understanding of your delivery performance. Gain real-time visibility into crucial data, such as delivery times, customer feedback, and order trends. Our vendor portal empowers you to make data-driven decisions, optimize operations, and enhance customer satisfaction.",
    },
    {
      imageName: carouselImg1,
      title: "Elevate Your Delivery Business",
      content:
        "Elevate your delivery operations with cutting-edge technology at its core. KIRFU utilizes state-of-the-art solutions to optimize deliveries, with a strong emphasis on route optimization. Partnering with us unlocks unprecedented opportunities for your business, expanding your reach, customer base, and revenue. Seamlessly integrating with the KIRFU platform, vendors can connect their inventory systems effortlessly, ensuring streamlined order processing. With real-time tracking, you and your customers stay informed every step of the way. Experience the ease and efficiency of KIRFU's platform, empowering your business to thrive in the competitive delivery landscape.",
    },
  ];

  return (
    <section id="vendors">
      <div className="driver-container">
        <div style={{ textAlign: "center" }}>
          <Typography className="textStyle" fontSize={"32px"}>
            For Vendors
          </Typography>
        </div>
        <Carousel
          indicatorIconButtonProps={{
            style: {
              // padding: "10px", // 1
              marginTop: "10px",
              // color: "blue", // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#9376E0", // 2
            },
          }}
          animation="slide"
          sx={{ marginTop: "8%", marginLeft: "4%", marginRight: "4%" }}
        >
          {carouselData.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

function Item(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px", // Adjust the gap between the image and card container
        marginLeft: "5%",
      }}
    >
      <div
        style={{
          width: "100%", // You can adjust this percentage based on your layout needs
          height: "100%", // You can adjust this percentage based on your layout needs
        }}
      >
        <img
          src={carouselImg1}
          alt="carousel"
          style={{ width: "70%", height: "70%" }}
        />
      </div>
      <div
        style={{
          width: "100%", // You can adjust this percentage based on your layout needs
          height: "100%", // You can adjust this percentage based on your layout needs
        }}
      >
        <Card
          sx={{
            width: "80%",
            height: "462px",
            borderRadius: "22px",
            // marginLeft: "15%",
            backgroundColor: "#8585854D",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "#fff",
            }}
          >
            <Typography fontWeight={500} fontSize={"18px"} align="center">
              {props.item.title}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              marginTop={"18px"} // Adjust the margin as needed
              align="center"
            >
              {props.item.content}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Vendors;
