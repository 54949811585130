import React from 'react'
import "./footer.css";
import { FacebookRounded, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';

const socialData = [
    { icon: <FacebookRounded />, socialLink: "https://www.facebook.com" },
    { icon: <Twitter />, socialLink: "https://www.twitter.com" },
    { icon: <Instagram />, socialLink: "https://www.instagram.com" },
    { icon: <LinkedIn />, socialLink: "https://www.linkedin.com" },
]

const Footer = () => {
    return (
        <>
            <div className='footerA' >
                <div style={{ textAlign: "left"}}>
                    <strong className='heading'>KIRFU</strong>
                    <p className='copyright' style={{ marginTop: "0" }}>
                        © Copyright 2023 Kirfu, Inc.
                    </p>
                </div>
                <div style={{marginLeft:"50px"}} >
                    <p className='subscribe' >Subscribe to our newsletter</p>
                    <div>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            style={{
                                backgroundColor: '#2D2C2C61',
                                color: '#FFF',
                                width: '295px',
                                height: '45px',
                                border: '1px solid #2D2C2C61', // Optional border
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px',
                                padding: '15px', // Optional padding
                                fontSize: '15px',
                            }}
                        />
                        <button
                            style={{
                                backgroundColor: '#9376E0', // Set the color
                                color: 'white',
                                border: 'none',
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px',
                                height: '45px', // Match the input height
                                fontSize: '15px',
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
                <div>
                    <p className='address' >
                        71-75 Shelton Street, Covent Garden,<br />
                        London WC2H 9JQ<br />
                        <span style={{ color: "#BCB4B4" }}>contact@kirfu.com</span>
                    </p>
                </div>
            </div>
            <div className='footerB' >
                {socialData.map((item, index) => (
                    <a key={index} href={item.socialLink} target="_blank" rel="noopener noreferrer" className="icon-container">
                        {item.icon}
                    </a>
                ))}
            </div>
        </>
    )
}

export default Footer