import { Button, TextField, Typography } from '@mui/material'
import React from 'react'
import "./contact.css";

const Contact = () => {
  return (
    <section id='contact' style={{ color: "white" }} >
      <div className='contactContainer' >
        <div>
          <Typography style={{ color: "white", fontWeight: 500, fontSize: '48px', lineHeight: '72px' }} >Want to Know <br />More?</Typography>
        </div>
        <div className='sendContainer' >
          <p className='enquire' >Enquire now</p>
          <TextField
            type="email"
            label="Enter your email"
            variant="outlined"
            sx={{
              width: '418px',
              borderRadius: '8px',
              background: '#5B5A5A', // Background color
              '& input': {
                background: 'transparent', // Set the input background to transparent
                '&:focus': {
                  background: '#5B5A5A', // Background color on focus
                },
                color: 'white', // Text color while typing
              },
            }}
            InputLabelProps={{
              style: {
                color: '#AAA1A1', // Text color
              },
            }}
          />
          <TextField
            multiline // Allow multiline input
            rows={3} // Set the number of rows
            label="Your message"
            variant="outlined"
            sx={{
              width: '418px',
              marginTop: '20px',
              borderRadius: '8px',
              background: '#5B5A5A', // Background color
              color: 'white', // Text color
              '& textarea': {
                background: 'transparent', // Set the input background to transparent
                '&:focus': {
                  background: '#5B5A5A', // Background color on focus
                },
                color: 'white', // Text color while typing
              },
            }}
            InputLabelProps={{
              style: {
                color: '#AAA1A1', // Text color
              },
            }}
          />
          <Button variant="text" color="primary" sx={{
            backgroundColor: "#D9D9D9",
            color: "#000",
            marginTop: '20px',
            width: "97px",
            '&:hover': {
              backgroundColor: '#9376E0', // Set the hover color
              color: 'white', // Text color on hover
            },
          }}>
            Send
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Contact